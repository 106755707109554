import { signUp, signIn ,signOut, fetchAuthSession,fetchUserAttributes, resetPassword, confirmResetPassword, updatePassword, resendSignUpCode, confirmSignUp} from 'aws-amplify/auth'
import store from '../store/index';


async function getSessionToken() {
    if(store.getters.getSharing){
        return null;
    }
    const session = await fetchAuthSession().catch(function (error) {
        console.log(`error getting currentSession ${error}`);
        return undefined;
    })

    let jwtToken = session ? session.tokens.idToken.toString() : undefined;

    if (jwtToken) {
        localStorage.setItem('authorization_token', jwtToken);
    }
    else {
        localStorage.removeItem('authorization_token');
    }

    return jwtToken;
}

async function getAccessLevel() {
    const user = await fetchUserAttributes();
    if(user){
        if(user['custom:access_level'] !== undefined && user['custom:access_level'] !== null && user['custom:access_level'] !== ''){
            sessionStorage.setItem('access_level', parseInt(user['custom:access_level']));
            return parseInt(user['custom:access_level']);
        }else{
            return 4;//set to 0 for development, 4 on deployment
        }
    }
    return 0;
}

async function userSignIn(username, password){
    try{
        const { isSignedIn } = await signIn({ username, password });
        console.log("isSignedIn: ", isSignedIn);
    }
    catch(error){
        console.log("error sign in: ", error);
        alert(error.message);
        window.location.reload();
        signOut();

    }
}


export {signUp,  userSignIn, signOut, getSessionToken, getAccessLevel, resetPassword, confirmResetPassword, updatePassword, resendSignUpCode, confirmSignUp };
